import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

const Redirect = () => {
    useEffect(() => {
        navigate('/get-started')
    }, [])
    return <></>
}

export default Redirect